import React from 'react'
import { useParams } from 'react-router-dom'
import styles from './styles.module.scss'
import SeniorTechnicalArtist from './SeniorTechnicalArtist'
import WebProductManager from './WebProductManager'
import GradientAnimation from '../components/GradientAnimation'
import SeniorWebDeveloper from './SeniorWebDeveloper/index'

function JobsDetails() {
  const { id } = useParams()

  return (
    <div className={styles.container}>
      <GradientAnimation />
      {id === '1' && <SeniorTechnicalArtist />}
      {id === '2' && <WebProductManager />}
      {id === '3' && <SeniorWebDeveloper />}
    </div>
  )
}

export default JobsDetails
